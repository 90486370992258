body {
  background-color: #ffffff;
  font-family: "Lato", sans-serif !important;
  /* padding-bottom: 40px; */
}
div.logo {
  width: 100%;
  height: auto;
  background: none;
  margin: 0 24px 20px 0;
  float: left;
  padding: 0px 15px;
}
div.logo img {
  height: 70px;
  margin: auto;
}
.thankyou-logo {
  margin: auto;
}
.ant-layout {
  min-height: 100vh;
}
/* .ant-layout-header {
  margin-bottom: 50px;
}
.ant-layout-header {
  background: #001529;
  padding: 0 50px;
  height: 64px;
  line-height: 64px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99999;
} */
.ant-avatar > img {
  width: 65% !important;
  height: 65% !important;
  display: block;
  margin-top: 5px;
}
.ant-badge > .ant-avatar > img,
.chat_img > span > .ant-avatar > img {
  width: 100% !important;
  height: 100% !important;
  display: block;
  margin-top: 0 !important;
}

.received_withd_msg > .ant-avatar > img {
  width: 100% !important;
  height: 100% !important;
  display: block;
  margin-top: 0 !important;
}
.cover-full {
  background-color: #f6f6f6;
  height: 100vh;
}
.cover-full .panel {
  border: none;
  border-radius: 0;
  padding-top: 20vh;
  height: 100vh;
  margin-bottom: 0;
  margin-left: -15px;
  margin-right: -15px;
}
.panel-body {
  padding: 40px 40px;
}
h4 {
  font-size: 24px;
  font-weight: 700;
  color: #131313;
  margin-bottom: 30px;
  padding-top: 5px;
  /* margin-top: 60px;
  */
  margin-bottom: 30px !important;
  letter-spacing: 0px;
  font-family: "Poppins", sans-serif !important;
}
p {
  font-size: 12px;
  margin-top: 25px;
  font-family: "Poppins", sans-serif !important;
}
.icon {
  font-size: 40px;
  color: #131313;
  margin-top: 35px;
  margin-bottom: 100px;
}
h3 {
  font-size: 30px;
  font-weight: 800;
  color: #131313;

  padding-top: 10px;
  font-family: "Poppins", sans-serif !important;
}
.ant-btn-primary {
  color: #fff;
  background-color: #ea8b2d !important;
  border-color: #ea8b2d !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #e68728 !important;
  border-color: #e68728 !important;
}
.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
.form-control {
  display: block;
  width: 100%;
  max-width: 100%;
  /* margin: auto; */
  height: 40px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #000;
  background-color: #f3f3f3;
  background-image: none;
  border: 2px solid #f5f5f5;
  border-radius: 2px;
  outline: none !important;
  box-shadow: none !important;
  margin-bottom: 20px;
}
.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #1b1b1b;
}
.loginlogo {
  height: 116px;
  margin: 0 auto;
  width: 65%;
  margin-bottom: 30px;
}
.ant-layout-header {
  height: 64px;
  padding: 0 30px;
  line-height: 64px;
  background: #fff;
}
.btn-default,
.btn-default:hover,
.btn-default:focus {
  color: #333;
  background-color: transparent;
  border: 3px solid #131313;
  border-radius: 0;
  font-size: 14px;
  font-weight: 700;
}
.noticon {
  margin-right: 20px;
}
.ant-badge-count {
  z-index: 10;
  min-width: 17px;
  height: 17px;
  padding: 0 5px;
  color: #fff;
  font-weight: normal;
  font-size: 8px;
  line-height: 15px;
  white-space: nowrap;
  text-align: center;
  background: #f5222d;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.ant-list-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 12px 10px;
  max-width: 300px;
}
.ant-list-item:hover {
  background-color: #f5f5f5;
}
a {
  font-weight: normal;
  color: #131313;
  text-decoration: none !important;
}
.searchdivbtn {
  padding-top: 30px;
}
.resulttitle {
  padding-left: 10px;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 15px;
}
.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: auto;
}
.ant-card-meta-description {
  font-size: 12px;
  font-weight: 900;
  color: rgba(0, 0, 0, 0.37);
  cursor: auto;
}
.forgot {
  margin-right: 35px;
  font-weight: 600;
}
.ant-input-number .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-disabled {
  /* background-color: #ffffff !important; */
  opacity: 1;
  cursor: not-allowed !important;
  color: #000 !important;
}
.selected .ant-card-meta-title,
.selected .ant-card-meta-description {
  color: #fff;
}
.selected {
  background-color: #ea8b2d;
  border-color: #ea8b2d;
}
.ant-form-item-label {
  text-align: left;
  vertical-align: middle;
  line-height: 39.9999px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
/* .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o{
  display: none !important;
} */
.hidden-visible {
  visibility: hidden;
  height: 10px;
}
.hidden-visible .ant-form-item {
  margin: 0 !important;
  padding: 0 !important;
}
.addp {
  background-color: #ea8b2d17;
  padding-top: 30px;
}
.allproduct {
  padding-top: 30px;
  min-height: 100%;
  padding-bottom: 30px;
}
.filterdiv {
  padding-bottom: 30px;
}
.addp .form-control {
  max-width: 250px;
  display: inline-block;
  /* float: left; */
}
.addproduct {
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 50px;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark .ant-menu-submenu-selected {
  background-color: #ea8b2d !important;
}

.ant-menu-submenu-title:hover {
  color: #fff !important;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #fff !important;
}
.ant-menu-sub li {
  padding-left: 25px !important;
}
.ant-form-item-label {
  text-align: left !important;
  vertical-align: middle;
  line-height: 39.9999px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

#productList {
  list-style-type: none;
  padding-left: 0;
}

#productList .panel {
  margin-bottom: 20px;
  border: none;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
}
#productList .panel-head {
  border: 1px solid #f9f9f9;
}
#productList .panel-head img {
  max-height: 100px;
  min-height: 100px;
  margin: 0 auto;
}
#productList .panel-body {
  padding: 10px;
  min-height: 150px;
  height: 150px;
}
#productList .panel-body h5 {
  line-height: 2rem;
}
#productList .panel-body h5 span {
  color: #a7a7a7;
  line-height: 2rem;
}
#productList .panel-footer {
  padding: 10px 15px;
  background-color: #ffffff;
  border-top: 1px solid #f9f9f9;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
#productList .panel-footer a {
  color: rgba(0, 0, 0, 0.65);
}

label {
  font-weight: normal !important;
  margin-bottom: 0 !important;
}
.ant-form-item-label {
  line-height: 30px !important;
}
.ant-form-inline .ant-form-item .ant-form-item-label
{
  line-height: 43px !important;
}
.formtitle {
  padding-bottom: 15px;
  margin-bottom: 20px;
  margin-top: 30px;
  color: #ea8b2d;
  font-size: 18px;
  border-bottom: 2px solid #f5f5f5;
}
.ant-select-disabled .ant-select-selection {
  /* background: #ffffff !important; */
  cursor: not-allowed;
}
.ant-select-disabled {
  color: rgba(0, 0, 0, 0.65) !important;
}

.presetimage {
  margin-bottom: 20px;
  position: relative;
  border: 1px dashed #d9d9d9;
  width: 104px;
  height: 104px;
  border-radius: 4px;
  background-color: #fafafa;
  text-align: center;
  transition: border-color 0.3s ease;
  vertical-align: top;
  margin-right: 8px;
}
.presetimage:hover {
  border-color: #40a9ff;
}
.presetimage .preimg {
  height: 100px;
  max-width: 100px;
}
.presetimage .anticon-close-circle {
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
}
.gm-style .gm-style-iw-c {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
  background-color: white;
  border-radius: 3px;
  padding: 12px;
  box-shadow: 0 2px 20px 1px rgba(0, 0, 0, 0.16);
}
.gm-style .gm-style-iw {
  font-weight: 400;
  font-size: 13px;
  overflow: hidden;
}

.gm-ui-hover-effect {
  display: none !important;
}

.trackingdiv {
  padding-top: 30px;
  padding-bottom: 80px;
}

h5.ongtitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  padding-right: 10px;
}
h5 .orderscount {
  float: right;
}

.ant-collapse {
  font-size: 14px;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff !important;
  border-radius: 0px !important;
  border: 1px solid #e8e8e8 !important;
  border-bottom: 0;
  height: 365px;
  overflow-y: scroll;
  margin-bottom: 20px !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
  background-color: #fdfdfd !important;
  border-radius: 0 !important;
}

.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 !important;
}

.gm-svpc {
  display: none;
}

.orderdetail {
  border: 1px solid #efefef;
  padding: 15px;
  border-radius: 0;
}

.orderdetail p span {
  float: right;
}

.subtotaldiv {
  border-bottom: 1px dotted #efefef;
  margin-bottom: 10px;
}

.ant-form-explain {
  color: #f5222d;
}

.invoice-title h2,
.invoice-title h3 {
  display: inline-block;
}

.table > tbody > tr > .no-line {
  border-top: none;
}

.table > thead > tr > .no-line {
  border-bottom: none;
}

.table > tbody > tr > .thick-line {
  border-top: 2px solid;
}
#components-dropdown-demo-dropdown-button .ant-dropdown-button {
  margin: 0 8px 8px 0;
}

.ant-btn-danger {
  color: #fff;
  background-color: #f5222d;
  border-color: #f5222d;
}
.center-item {
  display: -webkit-flex;
  display: flex; /* establish flex container */
  -webkit-flex-direction: column;
          flex-direction: column; /* make main axis vertical */
  -webkit-justify-content: center;
          justify-content: center; /* center items vertically, in this case */
  -webkit-align-items: center;
          align-items: center;
}
.m-t-30 {
  margin-top: 30px;
}
.m-t-100 {
  margin-top: 80px;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.my-float {
  margin-top: 22px;
}

/*media query*/
@media only screen and (max-width: 786px) {
}

/* Message Css */
.p-t-100 {
  padding-top: 80px;
}
.padd0 {
  padding: 0px;
}
.inbox_people {
  background: #ffffff none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 35%;
  border-right: 1px solid #efefef;
  height: 86vh;
  max-width: 370px;
}
.inbox_msg {
  border: 0px solid #efefef;
  clear: both;
  overflow: hidden;
}
.top_spac {
  margin: 20px 0 0;
}

.recent_heading {
  float: left;
  width: 40%;
}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}
img {
  max-width: 100%;
}
.headind_srch {
  padding: 10px 29px 10px 20px;
  overflow: hidden;
  border-bottom: 1px solid #efefef;
}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input {
  border: 1px solid #000000;
  border-width: 0 0 1px 0;
  width: 80%;
  padding: 2px 0 4px 6px;
  background: none;
}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_ib h5 {
  font-size: 15px;
  color: #464646;
  margin: 7px 0 8px 0;
  font-weight: 700;
}
.chat_ib h5 span {
  font-size: 12px;
  float: right;
  color: #848484;
  font-weight: 400;
}
.chat_ib p {
  font-size: 13px;
  color: #989898;
  margin: auto;
}
.chat_img {
  float: left;
  width: 15%;
  max-width: 40px;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: calc(100% - 40px);
}

.chat_people {
  overflow: hidden;
  clear: both;
}
.chat_list {
  border-bottom: 1px solid #efefef;
  margin: 0;
  padding: 10px 15px 10px;
}

.inbox_chat {
  height: 550px;
  overflow-y: scroll;
}

.active_chat {
  background: #fff3e8;
}
.incoming_msg {
  padding-bottom: 10px;
}
.incoming_msg_img {
  display: inline-block;
  width: 6%;
  margin: 12px 0 0;
  max-width: 40px;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}
.received_withd_msg p {
  background: #f3f3f3 none repeat scroll 0 0;
  border-radius: 0 10px 10px 10px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 6px 15px 6px 15px;
  width: 100%;
}
.received_withd_msg {
  width: 57%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}

.mesgs {
  float: left;
  padding: 0px;
  width: 100%;
  max-width: calc(100% - 370px);
}
.conversation {
  padding: 0px;
}
.convoinner {
  padding: 0px 15px 15px 15px;
}
.sent_msg p {
  background: #ea8b2d none repeat scroll 0 0;
  border-radius: 10px 0 10px 10px;
  color: #fff;
  font-size: 14px;
  margin: 0;
  padding: 6px 15px 6px 15px;
  width: 100%;
  margin-bottom: 5px;
}
.text-danger {
  color: #e80000 !important;
}
.outgoing_msg {
  overflow: hidden;
  margin: 0;
}
.sent_msg {
  float: right;
  width: 46%;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 93%;
  word-wrap: break-word;
  word-break: break-all;
}
.input_msg_write input:focus {
  border: none;
  outline: none;
}
.type_msg {
  border-top: 1px solid #efefef;
  position: relative;
  padding: 0px 15px 0px 15px;
}
.msg_send_btn {
  background: #ea8b2d none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 33px;
}
.messaging {
  padding: 0 0 0 0;
}
.msg_history {
  height: 516px;
  overflow-y: auto;
}
li {
  list-style-type: none;
}
ul {
  margin: 0 !important;
  padding: 0 !important;
}

.ant-badge-count {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, 15%);
  transform: translate(50%, 15%);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
}
.thumbnail {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
}
.thumbnail img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.ant-table-pagination.ant-pagination {
  float: right;
  margin: 16px 0 !important;
}
.ant-layout-sider-zero-width-trigger {
  background: #ea8b2d;
  top: 9px;
  right: -45px;
}
.p-t-20 {
  padding-top: 20px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-b-60 {
  margin-bottom: 60px;
}
.ant-layout-sider-zero-width-trigger {
  border-radius: 4px 4px 4px 4px;
}
.ant-card-cover img {
  border-radius: 2px 2px 0 0;
  height: 150px !important;
}
.mr-auto, .mx-auto {
  margin-right: auto !important;
}
.ant-btn > svg {
  line-height: 1;
  vertical-align: middle;
}

.text-white {
  color: rgb(255, 255, 255) !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 90% !important;
}
.text-big {
 font-weight: bold;
 font-size: 18px;
}
.strike
{
  text-decoration: line-through;
}
.visibleall
{
  cursor: pointer;
}
.ant-drawer-content {
  height: 100%;
  overflow-y: scroll;
}
.full-height {
    height: 100vh;
}

.flex-center {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.position-ref {
    position: relative;
}

.top-right {
    position: absolute;
    right: 10px;
    top: 18px;
}

.content {
    text-align: center;
}

.title {
    font-size: 84px;
}

.m-b-md {
    margin-bottom: 30px;
}

.three {
    padding: 20px 0;
    position: absolute;
    margin: 0 auto;
    left: 45%;
    top: 40%;
}
.loader {
    width: 50px;
    height: 40px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
}
.loader .div {
    width: 20px;
    height: 20px;
    display: inline-block;
}
.div1 {
    position: absolute;
    top: 0;
    left: calc(60% - 10px);
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    background-color: #000;
    -webkit-animation: squaremove 2s ease-in-out infinite;
            animation: squaremove 2s ease-in-out infinite;
}
.div2 {
    position: absolute;
    top: 0;
    left: calc(100% - -10px);
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    background-color: #000;
    -webkit-animation: squaremove 2s ease-in-out infinite;
            animation: squaremove 2s ease-in-out infinite;
}
.div3 {
    position: absolute;
    left: 0;
    bottom: 0;
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
    background-color: #EA8B2D;
}
.div4 {
    position: absolute;
    left: calc(100% - 10px);
    bottom: 0;
    -webkit-animation-delay: 4.5s;
            animation-delay: 4.5s;
    background-color: #EA8B2D;
}

@-webkit-keyframes squaremove {
    0%,
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    25% {
        -webkit-transform: translate(-40px, 0px);
        transform: translate(-40px, 0px);
    }

    50% {
        -webkit-transform: translate(-40px, 40px);
        transform: translate(-40px, 40px);
    }

    75% {
        -webkit-transform: translate(0px, 40px);
        transform: translate(0px, 40px);
    }
}

@keyframes squaremove {
    0%,
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    25% {
        -webkit-transform: translate(-40px, 0px);
        transform: translate(-40px, 0px);
    }

    50% {
        -webkit-transform: translate(-40px, 40px);
        transform: translate(-40px, 40px);
    }

    75% {
        -webkit-transform: translate(0px, 40px);
        transform: translate(0px, 40px);
    }
}


