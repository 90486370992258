.full-height {
    height: 100vh;
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.position-ref {
    position: relative;
}

.top-right {
    position: absolute;
    right: 10px;
    top: 18px;
}

.content {
    text-align: center;
}

.title {
    font-size: 84px;
}

.m-b-md {
    margin-bottom: 30px;
}

.three {
    padding: 20px 0;
    position: absolute;
    margin: 0 auto;
    left: 45%;
    top: 40%;
}
.loader {
    width: 50px;
    height: 40px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
}
.loader .div {
    width: 20px;
    height: 20px;
    display: inline-block;
}
.div1 {
    position: absolute;
    top: 0;
    left: calc(60% - 10px);
    animation-delay: 0s;
    background-color: #000;
    animation: squaremove 2s ease-in-out infinite;
}
.div2 {
    position: absolute;
    top: 0;
    left: calc(100% - -10px);
    animation-delay: 0s;
    background-color: #000;
    animation: squaremove 2s ease-in-out infinite;
}
.div3 {
    position: absolute;
    left: 0;
    bottom: 0;
    animation-delay: 4s;
    background-color: #EA8B2D;
}
.div4 {
    position: absolute;
    left: calc(100% - 10px);
    bottom: 0;
    animation-delay: 4.5s;
    background-color: #EA8B2D;
}

@keyframes squaremove {
    0%,
    100% {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    25% {
        -webkit-transform: translate(-40px, 0px);
        -ms-transform: translate(-40px, 0px);
        -o-transform: translate(-40px, 0px);
        transform: translate(-40px, 0px);
    }

    50% {
        -webkit-transform: translate(-40px, 40px);
        -ms-transform: translate(-40px, 40px);
        -o-transform: translate(-40px, 40px);
        transform: translate(-40px, 40px);
    }

    75% {
        -webkit-transform: translate(0px, 40px);
        -ms-transform: translate(0px, 40px);
        -o-transform: translate(0px, 40px);
        transform: translate(0px, 40px);
    }
}

